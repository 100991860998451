import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BadgeModule } from 'src/app/shared/badge/badge.module';
import { HelpIconModule } from 'src/app/shared/icons/help-icon/help-icon.module';
import { SvgIconModule } from 'src/app/shared/icons/svg-icon/svg-icon.module';
import { ImageSquareModule } from 'src/app/shared/image/image-square/image-square.module';
import { CompanyVerificationBadgeModule } from '../../shared/company-verification-badge/company-verification-badge.module';
import { CompanyStatusModule } from '../company-status/company-status.module';
import { CompanyCardComponent } from './company-card.component';



@NgModule({
  declarations: [
    CompanyCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SvgIconModule,
    MatButtonModule,
    CompanyVerificationBadgeModule, 
    ImageSquareModule, 
    CompanyStatusModule, 
    MatBadgeModule, 
    MatTooltipModule, 
    HelpIconModule, 
    BadgeModule
  ],
  exports: [ CompanyCardComponent ]
})
export class CompanyCardModule { }
